import { Component, Input, OnInit, ViewEncapsulation, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { Apollo } from 'apollo-angular';
import { ApplicationConstants, EventConstants, SessionConstants } from 'src/app/constants/app.constants';
import { GetCategoryQuery } from 'src/app/services/graphql/getcategory.graphql';
import { CreateSoftLockMutation } from 'src/app/services/graphql/createSoftLock.graphql';
import { LoginService } from 'src/app/services/login.service';
import { RandomUserService } from 'src/app/services/randomeUser.service';
import { UpsertUserCachedTokenMutation } from 'src/app/services/graphql/upsertUserCachedToken.graphql';
import { RegisterNewCouponCodeMutation } from 'src/app/services/graphql/registerNewCouponCode.graphql';
import { GenerateCouponCodeMutation } from 'src/app/services/graphql/generateCouponCode.graphql';
import { AttachMaskedUserDetailsMutation } from 'src/app/services/graphql/attachMaskedUserDetails.graphql';
import { TrackLeadGenCouponDealMutation } from 'src/app/services/graphql/trackLeadGenCouponDeal.graphql';
import { GetProductDetailsQuery } from 'src/app/services/graphql/getProductDetails.graphql';
import { GetProductListQuery } from "src/app/services/graphql/getProductList.graphql";
import { GetProductSchedulesQuery } from 'src/app/services/graphql/getProductSchedules.graphql';
import { GetProductSchedulesWthSlotsQuery } from 'src/app/services/graphql/getProductScheduleWthSlots.graphql';
import { GetProductRatingQuery } from 'src/app/services/graphql/getProductRating.graphql';
import { GetProductImagesQuery } from 'src/app/services/graphql/getProductImages.graphql';
import { GetProductAttributesQuery } from 'src/app/services/graphql/getProductAttribute.graphql';
import { ProductLikeMutation } from "src/app/services/graphql/productLike.graphql";
import { TrackOfferViewMutation } from "src/app/services/graphql/trackOfferView.graphql";
import { SessionService } from 'src/app/services/session.service';
import { HeaderStickyComponent } from 'src/app/layouts/header/header-sticky/header-sticky.component';
import { HeaderMobileComponent } from 'src/app/layouts/header/header-mobile/header-mobile.component';
import { HeaderComponent } from 'src/app/layouts/header/header/header.component';
import { BehaviorSubject } from 'rxjs';
import { map, pluck, tap } from "rxjs/operators";
import { Meta, Title } from '@angular/platform-browser';

import {NgbDate, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import { CurencyPipe } from '../../../pipes/curency/curency.pipe';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Lightbox } from 'ngx-lightbox';

import { GetDayTemplatesQuery } from "src/app/services/graphql/getDayTemplates.graphql";
import { ScheduleService } from "src/app/services/schedule.service";
import { Console } from 'console';
import { faLessThanEqual } from '@fortawesome/free-solid-svg-icons';

interface OfferSEO {
  offer_image: string;
  offer_image_height: number;
  offer_image_width: number;
  offer_image_type: string;
  offer_description: string;
  offer_title: string;
  keywords: string;
}

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  providers: [CurencyPipe],
  encapsulation: ViewEncapsulation.None
})
export class ProductComponent implements OnInit {

  readonly offerSEODetails$ = this.updatePageMeta((<OfferSEO>(<BehaviorSubject<any>>this.route.data).getValue()["offerSEODetails"]));

  public productAddedToCart;
  public productTotal;

  @ViewChild(HeaderStickyComponent, { static: true })
  headerStickyComponent: HeaderStickyComponent;

  @ViewChild(HeaderComponent, { static: true })
  headerComponent: HeaderComponent;

  @ViewChild(HeaderMobileComponent, { static: true })
  headerMobileComponent: HeaderMobileComponent;
  
  @ViewChild("dealModalPopup",{static:true}) dealModalPopup:ElementRef;
  
  loadPage = false;

  dealModalPopupText = "";
  dealModalPopupDealString = "";
  couponCodeEmail = "";
  couponCodeData = null;
  couponCodeSurpriseFlag = false;
  getProductDetailsData: any;
productScheduleIds = [];
  loadComplete = false;
  getProductImagesData = [];
  productselectedId: number;
  getImage: boolean = false;
  subscription: any;
  statusdata: String;
  status: "";
  prodImageLinkData: any;
  getProductAttributeData: any;
  userAddressCheck = true;
  getCategoriesAddress: boolean = false;
  addressGroupdata: any;
  getCategoriesImage: boolean = false;
  imageCategoriesdata: string;
  productImages = [];
  addressFlag = false;
  categoryId = 0;
  public productRating = {rating: 0, likes: 0};
  public likes = "0";
  public productDescription = ""
  public guidelines = ""
  validUser = false;
  productSchedules = [];
rawProductSchedules: any = null;
  scheduleFreqMap = new Map();
  closeGuidelinesResult = "";
  selectedSlots = new Map();
  totalPrice = "";
  totalPriceInt = 0.0;
  token = "";
  email = "";
  checkedBoxMap = new Map();
  checkedBoxGranularMap = new Map();
  selectBoxGranularMap = new Map();
  productName = "";
  @ViewChild('scheduleFrequencyOptions') scheduleFrequencyOptions!: ElementRef;
  @ViewChild('expandedContent', {static: false}) scheduleModal: TemplateRef<void>;

  startYear = "----";
  startMonth = "--";
  startDay = "--";
  endYear = "----";
  endMonth = "--";
  endDay = "--";
  offerList = null;
  isShowDiv = true;

dayTemplateData: any = null;


  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate;
  toDate: NgbDate | null = null;

  frmAddCart = this.fb.group({
    scheduleFrequency: [null, Validators.compose([Validators.required])],
    schedulePrice: [null, Validators.compose([Validators.required])]
  });

  constructor(
    private apollo: Apollo,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private sessionService: SessionService,
    private getProductDetailsQuery: GetProductDetailsQuery,
    private getProductImagesQuery: GetProductImagesQuery,
    private getCategoryQuery: GetCategoryQuery,
    private getProductAttributesQuery: GetProductAttributesQuery,
    private getProductRatingQuery: GetProductRatingQuery,
    private getProductSchedulesQuery: GetProductSchedulesQuery,
private getProductSchedulesWthSlotsQuery: GetProductSchedulesWthSlotsQuery,
    private createSoftLockMutation: CreateSoftLockMutation,
    private productLikeMutation: ProductLikeMutation,
    private loginService: LoginService,
    private route: ActivatedRoute,
    private r: Router,
    private _lightbox: Lightbox,
    calendar: NgbCalendar,
    private toastr: ToastrService,
    private currencyPipe: CurencyPipe,
    private upsertUserCachedTokenMutation: UpsertUserCachedTokenMutation,
    private getProductListQuery: GetProductListQuery,
    private meta: Meta,
    private titleService: Title,
    private scheduleService: ScheduleService,
    private getDayTemplatesQuery: GetDayTemplatesQuery,
    private trackOfferViewMutation: TrackOfferViewMutation,
    private registerNewCouponCodeMutation: RegisterNewCouponCodeMutation,
    private generateCouponCodeMutation: GenerateCouponCodeMutation,
    private trackLeadGenCouponDealMutation: TrackLeadGenCouponDealMutation,
    private attachMaskedUserDetailsMutation: AttachMaskedUserDetailsMutation,
    private randomUserService: RandomUserService
  ) {
    this.loadAlbum();
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 0);
   }

   updatePageMeta(offerSeo) {
    this.meta.updateTag({property: "og:url", content: this.r.url});
    this.meta.updateTag({property: "og:image", content: EventConstants.CONST_DEFAULT_OFFER_IMAGE});
    this.meta.updateTag({name: "image", content: EventConstants.CONST_DEFAULT_OFFER_IMAGE});
    this.meta.updateTag({name: "twitter:image", content: EventConstants.CONST_DEFAULT_OFFER_IMAGE});
    this.meta.updateTag({itemprop: "image", content: EventConstants.CONST_DEFAULT_OFFER_IMAGE});

    if(offerSeo.offer_image != null && offerSeo.offer_image != "") {
      this.meta.updateTag({property: "og:image", content: offerSeo.offer_image});
      this.meta.updateTag({name: "image", content: offerSeo.offer_image});
      this.meta.updateTag({name: "twitter:image", content: offerSeo.offer_image});
      this.meta.updateTag({property: "og:image:secure_url", content: offerSeo.offer_image});
      this.meta.updateTag({property: "og:image:height", content: String(offerSeo.offer_image_height)});
      this.meta.updateTag({property: "og:image:width", content: String(offerSeo.offer_image_width)});
      this.meta.updateTag({property: "og:image:type", content: offerSeo.offer_image_type});
      this.meta.updateTag({itemprop: "image", content: offerSeo.offer_image});
    }

    this.meta.updateTag({name: "keywords", content: offerSeo.keywords});

    if(offerSeo.offer_title.length > 60) {
      this.titleService.setTitle(offerSeo.offer_title.substring(0, 57) + "...");
      this.meta.updateTag({property: "og:title", content: offerSeo.offer_title.substring(0, 57) + "..."});
      this.meta.updateTag({name: "twitter:title", content: offerSeo.offer_title.substring(0, 57) + "..."});
    } else {
      this.titleService.setTitle(offerSeo.offer_title);
      this.meta.updateTag({property: "og:title", content: offerSeo.offer_title});
      this.meta.updateTag({name: "twitter:title", content: offerSeo.offer_title});
    }
    this.meta.updateTag({itemprop: "name", content: offerSeo.offer_title});

    // HTML tags contain text
    var div = document.createElement("div");
    div.innerHTML = offerSeo.offer_description;
    var description_text: string = div.textContent || div.innerText || "";
    if(description_text.length > 160) {
      this.meta.updateTag({name: "description", content: description_text.substring(0, 157) + "..."});
      this.meta.updateTag({property: "og:description", content: description_text.substring(0, 157) + "..."});
      this.meta.updateTag({name: "twitter:description", content: description_text.substring(0, 157) + "..."});
    } else {
      this.meta.updateTag({name: "description", content: description_text});
      this.meta.updateTag({property: "og:description", content: description_text});
      this.meta.updateTag({name: "twitter:description", content: description_text});
    }
    this.meta.updateTag({itemprop: "description", content: description_text});
   }

   loadAlbum() {
    this.productImages.forEach(element => {
      const src = element.image;
      const caption = '';
      const thumb = element.image;
      const album = {
        src: src,
        caption: caption,
        thumb: thumb
      };
      this._album.push(album);
    });
  }

  toggleDisplayDiv() {  
    this.isShowDiv = !this.isShowDiv;  
  }

  titleCase(str) {
    str = str.toLowerCase().split(' ');
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1); 
    }
    return str.join(' ');
  }

  getWeekNumber(str) {
    var d = new Date(str + " 00:00:00");
    if((new Date(d.getFullYear(), 0, 1)) === (new Date(d.getFullYear(), d.getMonth(), d.getDate()))) {
      d.setDate(d.getDate() - 1);
    }
    var onejan = new Date(d.getFullYear(), 0, 1);
    return Math.ceil((((d.getTime() - onejan.getTime()) / 86400000) + onejan.getDay()) / 7);
  }

  scrollToBookingSection() {
    // element which needs to be scrolled to
    var element = document.querySelector("#booking-container");

    // scroll to element
    element.scrollIntoView({ behavior: 'smooth', block: 'start'});
  }

  onAddLike(productId: Number) {
    let idToken = this.sessionService.getKeyValues("token", "id_token");
    let email = this.sessionService.getLocalKeyValues("user", "email");

    this.apollo
      .mutate({
        mutation: this.productLikeMutation.document,
        variables: {
          email: email,
          idToken: idToken,
          productId: productId
        },
      })
      .subscribe(
        ({ data }) => {
          this.toastr.success("Thank you for liking the offer.");
        }
      );
  }

  loadBriefOfferInfo() {
    this.apollo
      .query({
        query: this.getProductListQuery.document,
        variables: {
          offer_id: this.productselectedId
        },
      })
      .subscribe(
        ({ data }) => {
          if (data["getProductList"] && data["getProductList"].length > 0) {
            this.offerList = data["getProductList"][0];
          }
        },
        (error) => {
          console.log(error.message);
          this.getProductListQuery.populateProductList(error.message, false, true);
        }
      );
  }

  async loadSchedules(initLoad: boolean = false) {
    if(initLoad == false) {
    document.getElementById("loadSchedulesButton").classList.add("is-loading");
    (<HTMLInputElement>document.getElementById("loadSchedulesButton")).disabled = true;
    }
    let productSchedulesTemp = this.productSchedules;
    this.loadComplete = false;
        try {
      if(initLoad == false && (this.scheduleFrequencyOptions.nativeElement.value != "hourly" && 
      this.scheduleFrequencyOptions.nativeElement.value != "daily" && 
      this.scheduleFrequencyOptions.nativeElement.value != "weekly" &&
      this.scheduleFrequencyOptions.nativeElement.value != "monthly" &&
      this.scheduleFrequencyOptions.nativeElement.value != "custom")) {
      alert("Please select appropriate schedule type.");
        if(initLoad == false) {
      document.getElementById("loadSchedulesButton").classList.remove("is-loading");
    (<HTMLInputElement>document.getElementById("loadSchedulesButton")).disabled = false;
        }
this.loadComplete = true;
      return false;
    }

      if(initLoad == false && (this.startYear == "----")) {
        alert("Please select appropriate start date or range of dates.");
        if(initLoad == false) {
      document.getElementById("loadSchedulesButton").classList.remove("is-loading");
    (<HTMLInputElement>document.getElementById("loadSchedulesButton")).disabled = false;
            }
this.loadComplete = true;
      return false;
    }

this.productSchedules = [];
  
    var startDate = this.startYear + "-" + this.startMonth + "-" + this.startDay;
      var endDate = null;
      if(this.endYear != "----") {
        endDate = this.endYear + "-" + this.endMonth + "-" + this.endDay;
      }
      
    var scheduleFrequency = this.frmAddCart.controls.scheduleFrequency.value;
    var day_array = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    if(initLoad == true) {
      startDate = null;
      endDate = null;
      scheduleFrequency = null;
    }

    let token = this.sessionService.getKeyValues("token", "id_token");
      let email = this.sessionService.getLocalKeyValues("user", "email");

    var apiResult = await this.apollo
      .query({
        query: this.getProductSchedulesWthSlotsQuery.document,
        variables: {
          productId: <number>this.productselectedId,
          startDate: startDate,
          endDate: endDate,
          scheduleFrequency: scheduleFrequency
        },
        fetchPolicy: "network-only"
      }).toPromise();
  
      var finalSchedules = [];

      if(apiResult["data"]["getProductSchedulesWthSlots"]) {
        this.productScheduleIds = apiResult["data"]["getProductSchedulesWthSlots"];
  
        for(const scheduleId of this.productScheduleIds) {
          var apiScheduleResults = await this.apollo
      .query({
        query: this.getProductSchedulesQuery.document,
        variables: {
          productId: <number>this.productselectedId,
          startDate: startDate,
          endDate: endDate,
          scheduleId: scheduleId,
          getDaySlots: true,
          scheduleFrequency: scheduleFrequency

        },
        fetchPolicy: "network-only"
      }).toPromise();
  
          finalSchedules.push(apiScheduleResults["data"]["getProductSchedules"]);
        }
      }
      if(finalSchedules.length > 0) {
        this.rawProductSchedules = finalSchedules;
              this.apollo
              .mutate({
                mutation: this.getDayTemplatesQuery.document,
                variables: {
                  productId: <number>this.productselectedId
                  }
              })
              .subscribe(
        ({ data }) => {
          this.dayTemplateData = this.scheduleService.constructDayTemplateData(data["getProductDayTemplates"]);
                  this.loadComplete = true;
            var cntr = 0;
            this.rawProductSchedules.forEach(scheduleArr => {
                    if(scheduleArr.length <= 0) {
                      return;
                    }
              var schedule = scheduleArr[0];
              var tempStartDate = null;
              var tempEndDate = null;
              var guidelines = schedule["guidelines"];
              var daySlot = [];

              var full_refund_days = 0;
              var half_refund_days = 0;

              schedule["cancellation_policies"].forEach(cancellation_policy => {
                if(cancellation_policy["type"] == "half_refund") {
                  half_refund_days = cancellation_policy["days"];
                }

                if(cancellation_policy["type"] == "full_refund") {
                  full_refund_days = cancellation_policy["days"];
                }
              });

              if(guidelines != null && guidelines != "") {
                guidelines = this.nl2br(guidelines).replace(/\s/g, "<span class ='bg-white'> </span>");
              }

              if(initLoad == true) {
                this.scheduleFreqMap.set(schedule.schedule_frequency, this.titleCase(schedule.schedule_frequency));
              }

              var scheduleSlotTimes = new Map();

              var total_slots = schedule.slots.length;

              var count = 0;
                    var allTimeSlots = new Map();
              schedule.slots.forEach(slot => {
                count++;
                var timeSlots = new Map();
                slot.day_slots.forEach(daySlot => {
                  var startHour = (String(this.dayTemplateData[daySlot].start_hour).length == 1) ? "0" + this.dayTemplateData[daySlot].start_hour : this.dayTemplateData[daySlot].start_hour;
                  var startMinute = (String(this.dayTemplateData[daySlot].start_minute).length == 1) ? "0" + this.dayTemplateData[daySlot].start_minute : this.dayTemplateData[daySlot].start_minute;
                  var endHour = (String(this.dayTemplateData[daySlot].end_hour).length == 1) ? "0" + this.dayTemplateData[daySlot].end_hour : this.dayTemplateData[daySlot].end_hour;
                  var endMinute = (String(this.dayTemplateData[daySlot].end_minute).length == 1) ? "0" + this.dayTemplateData[daySlot].end_minute : this.dayTemplateData[daySlot].end_minute;

                  scheduleSlotTimes.set(startHour + ":" + startMinute + " till " + endHour + ":" + endMinute, {
                    booked_count: 0, // To be assigned later
                    day_template_slot_id: this.dayTemplateData[daySlot].day_template_slot_id,
                    end_hour: this.dayTemplateData[daySlot].end_hour,
                    end_minute: this.dayTemplateData[daySlot].end_minute,
                    start_minute: this.dayTemplateData[daySlot].start_minute,
                    start_hour: this.dayTemplateData[daySlot].start_hour,
                    slot_hours: this.dayTemplateData[daySlot].slot_hours,
                    slot_minutes: this.dayTemplateData[daySlot].slot_minutes,
                    day_template_id: slot.day_template_id
                  });

                  timeSlots.set(startHour + ":" + startMinute + " till " + endHour + ":" + endMinute, {
                    booked_count: 0, // To be assigned later
                    day_template_slot_id: this.dayTemplateData[daySlot].day_template_slot_id,
                    end_hour: this.dayTemplateData[daySlot].end_hour,
                    end_minute: this.dayTemplateData[daySlot].end_minute,
                    start_minute: this.dayTemplateData[daySlot].start_minute,
                    start_hour: this.dayTemplateData[daySlot].start_hour,
                    slot_hours: this.dayTemplateData[daySlot].slot_hours,
                    slot_minutes: this.dayTemplateData[daySlot].slot_minutes,
                    day_template_id: slot.day_template_id
                  });
                });

                if(schedule["schedule_frequency"] == "weekly") {
                  if(tempStartDate != null && tempEndDate != null) {
                    if((this.getWeekNumber(tempStartDate) != this.getWeekNumber(slot.date)) || total_slots == count) {
                      if(total_slots == count) {
                        tempEndDate = slot.date;
                        daySlot.push({
                          week_day: day_array[(new Date(slot["date"])).getDay()],
                          date: slot["date"],
                          day_template_id: slot["day_template_id"],
                          slot_id: slot["slot_id"],
                          template_id: slot["template_id"],
                          template_type: slot["template_type"],
                          time_slots: timeSlots
                        });
                      }
                      var full_refund_date: String = "";
                      var half_refund_date: String = "";
                      if(full_refund_days != 0) {
                        var temp_date = new Date(tempStartDate);
                        temp_date.setDate(temp_date.getDate() - full_refund_days);
                        full_refund_date = temp_date.getFullYear() + "-" + (((temp_date.getMonth() + 1).toString().length < 2) ? "0" : "") + (temp_date.getMonth() + 1) + "-" + ((temp_date.getDate().toString().length < 2) ? "0" : "") + temp_date.getDate();
                      }
                      
                      if(half_refund_days != 0) {
                        var temp_date = new Date(tempStartDate);
                        temp_date.setDate(temp_date.getDate() - half_refund_days);
                        half_refund_date = temp_date.getFullYear() + "-" + (((temp_date.getMonth() + 1).toString().length < 2) ? "0" : "") + (temp_date.getMonth() + 1) + "-" + ((temp_date.getDate().toString().length < 2) ? "0" : "") + temp_date.getDate();
                      }

                      this.productSchedules.push({schedule_days: tempStartDate + " to " + tempEndDate, 
                      week_day: "-",
                      guidelines: guidelines,
                      frequency: schedule["schedule_frequency"],
                      price: this.currencyPipe.transform(schedule["calculated_price"]),
                      discounted_price: this.currencyPipe.transform(schedule["discounted_price"]),
                      int_discounted_price: schedule["discounted_price"],
                      intPrice: schedule["calculated_price"],
                      schedules: scheduleSlotTimes,
                      buffer_days: schedule["buffer_days"],
                      full_refund_date: full_refund_date,
                      half_refund_date: half_refund_date,
                      slots: daySlot,
                      schedule_id: schedule["schedule_id"],
                      slot_id: slot["slot_id"],
                            allTimeSlots: allTimeSlots,
                      cntr: cntr});
                      cntr++;
                      scheduleSlotTimes = new Map();
                      daySlot = [];
                            allTimeSlots = new Map();

                      tempStartDate = null;
                      tempEndDate = null;
                    }
                  }
                } else if(schedule["schedule_frequency"] == "hourly" || schedule["schedule_frequency"] == "daily") {

                    var full_refund_date: String = "";
                    var half_refund_date: String = "";
                    
                    
                    if(full_refund_days != 0) {
                      var temp_date = new Date(slot.date);
                      temp_date.setDate(temp_date.getDate() - full_refund_days);
                      full_refund_date = temp_date.getFullYear() + "-" + (((temp_date.getMonth() + 1).toString().length < 2) ? "0" : "") + (temp_date.getMonth() + 1) + "-" + ((temp_date.getDate().toString().length < 2) ? "0" : "") + temp_date.getDate();
                    }
                    
                    if(half_refund_days != 0) {
                      var temp_date = new Date(slot.date);
                      temp_date.setDate(temp_date.getDate() - half_refund_days);
                      half_refund_date = temp_date.getFullYear() + "-" + (((temp_date.getMonth() + 1).toString().length < 2) ? "0" : "") + (temp_date.getMonth() + 1) + "-" + ((temp_date.getDate().toString().length < 2) ? "0" : "") + temp_date.getDate();
                    }
                    this.productSchedules.push({schedule_days: slot.date,
                      week_day: day_array[(new Date(slot.date)).getDay()], 
                      guidelines: guidelines,
                      frequency: schedule["schedule_frequency"],
                      price: this.currencyPipe.transform(schedule["calculated_price"]),
                      discounted_price: this.currencyPipe.transform(schedule["discounted_price"]),
                      int_discounted_price: schedule["discounted_price"],
                      intPrice: schedule["calculated_price"],
                      buffer_days: schedule["buffer_days"],
                      schedules: scheduleSlotTimes,
                      full_refund_date: full_refund_date,
                      half_refund_date: half_refund_date,
                      schedule_id: schedule["schedule_id"],
                      slot_id: slot["slot_id"],
                            allTimeSlots: null,
                      slots: null,
                      cntr: cntr});
                    cntr++;
                    scheduleSlotTimes = new Map();
                          allTimeSlots = new Map();

                    tempStartDate = null;
                    tempEndDate = null;
                } else if(schedule["schedule_frequency"] == "monthly") {
                  if(tempStartDate != null && tempEndDate != null) {

                    var full_refund_date: String = "";
                    var half_refund_date: String = "";
                    if(full_refund_days != 0) {
                      var temp_date = new Date(tempStartDate);
                      temp_date.setDate(temp_date.getDate() - full_refund_days);
                      full_refund_date = temp_date.getFullYear() + "-" + (((temp_date.getMonth() + 1).toString().length < 2) ? "0" : "") + (temp_date.getMonth() + 1) + "-" + ((temp_date.getDate().toString().length < 2) ? "0" : "") + temp_date.getDate();
                    }
                    
                    if(half_refund_days != 0) {
                      var temp_date = new Date(tempStartDate);
                      temp_date.setDate(temp_date.getDate() - half_refund_days);
                      half_refund_date = temp_date.getFullYear() + "-" + (((temp_date.getMonth() + 1).toString().length < 2) ? "0" : "") + (temp_date.getMonth() + 1) + "-" + ((temp_date.getDate().toString().length < 2) ? "0" : "") + temp_date.getDate();
                    }

                    if(((new Date(tempStartDate)).getMonth() != (new Date(slot.date)).getMonth()) || total_slots == count) {
                      if(total_slots == count) {
                        tempEndDate = slot.date;
                        daySlot.push({
                          week_day: day_array[(new Date(slot["date"])).getDay()],
                          date: slot["date"],
                          day_template_id: slot["day_template_id"],
                          slot_id: slot["slot_id"],
                          template_id: slot["template_id"],
                          template_type: slot["template_type"],
                          time_slots: timeSlots
                        });
                      }
                      this.productSchedules.push({schedule_days: tempStartDate + " to " + tempEndDate, 
                      week_day: "-",
                      guidelines: guidelines,
                      frequency: schedule["schedule_frequency"],
                      price: this.currencyPipe.transform(schedule["calculated_price"]),
                      discounted_price: this.currencyPipe.transform(schedule["discounted_price"]),
                      int_discounted_price: schedule["discounted_price"],
                      intPrice: schedule["calculated_price"],
                      buffer_days: schedule["buffer_days"],
                      schedules: scheduleSlotTimes,
                      full_refund_date: full_refund_date,
                      half_refund_date: half_refund_date,
                      schedule_id: schedule["schedule_id"],
                      slots: daySlot,
                      slot_id: slot["slot_id"],
                            allTimeSlots: allTimeSlots,
                      cntr: cntr});
                      cntr++;
                      scheduleSlotTimes = new Map();
                      daySlot = [];
                            allTimeSlots = new Map();

                      tempStartDate = null;
                      tempEndDate = null;
                    }
                  }
                } else if(schedule["schedule_frequency"] == "custom") {
                  if(tempStartDate != null && tempEndDate != null) {

                    var full_refund_date: String = "";
                    var half_refund_date: String = "";
                    if(full_refund_days != 0) {
                      var temp_date = new Date(tempStartDate);
                      temp_date.setDate(temp_date.getDate() - full_refund_days);
                      full_refund_date = temp_date.getFullYear() + "-" + (((temp_date.getMonth() + 1).toString().length < 2) ? "0" : "") + (temp_date.getMonth() + 1) + "-" + ((temp_date.getDate().toString().length < 2) ? "0" : "") + temp_date.getDate();
                    }
                    
                    if(half_refund_days != 0) {
                      var temp_date = new Date(tempStartDate);
                      temp_date.setDate(temp_date.getDate() - half_refund_days);
                      half_refund_date = temp_date.getFullYear() + "-" + (((temp_date.getMonth() + 1).toString().length < 2) ? "0" : "") + (temp_date.getMonth() + 1) + "-" + ((temp_date.getDate().toString().length < 2) ? "0" : "") + temp_date.getDate();
                    }

                    if(total_slots == count) {
                      tempEndDate = slot.date;
                      daySlot.push({
                        week_day: day_array[(new Date(slot["date"])).getDay()],
                        date: slot["date"],
                        day_template_id: slot["day_template_id"],
                        slot_id: slot["slot_id"],
                        template_id: slot["template_id"],
                        template_type: slot["template_type"],
                        time_slots: timeSlots
                      });
                      
                      this.productSchedules.push({schedule_days: tempStartDate + " to " + tempEndDate, 
                      week_day: "-",
                      guidelines: guidelines,
                      frequency: schedule["schedule_frequency"],
                      price: this.currencyPipe.transform(schedule["calculated_price"]),
                      discounted_price: this.currencyPipe.transform(schedule["discounted_price"]),
                      int_discounted_price: schedule["discounted_price"],
                      intPrice: schedule["calculated_price"],
                      buffer_days: schedule["buffer_days"],
                      schedules: scheduleSlotTimes,
                      full_refund_date: full_refund_date,
                      half_refund_date: half_refund_date,
                      schedule_id: schedule["schedule_id"],
                      slots: daySlot,
                      slot_id: slot["slot_id"],
                            allTimeSlots: allTimeSlots,
                      cntr: cntr});
                      cntr++;
                      scheduleSlotTimes = new Map();
                      daySlot = [];
                            allTimeSlots = new Map();

                      tempStartDate = null;
                      tempEndDate = null;
                    }
                  }
                }

                if(tempStartDate == null) {
                  tempStartDate = slot.date;
                }

                if(tempEndDate == null) {
                  tempEndDate = slot.date;
                }
                
                if(Date.parse(tempStartDate) > Date.parse(slot.date)) {
                  tempStartDate = slot.date;
                }

                if(Date.parse(tempEndDate) < Date.parse(slot.date)) {
                  tempEndDate = slot.date;
                }

                daySlot.push({
                  week_day: day_array[(new Date(slot["date"])).getDay()],
                  date: slot["date"],
                  day_template_id: slot["day_template_id"],
                  slot_id: slot["slot_id"],
                  template_id: slot["template_id"],
                  template_type: slot["template_type"],
                  time_slots: timeSlots
                });

                      allTimeSlots = new Map([...allTimeSlots.entries(), ...timeSlots.entries()])
      
                timeSlots = new Map();

              });
            });

                  if(initLoad == false) {
            document.getElementById("loadSchedulesButton").classList.remove("is-loading");
    (<HTMLInputElement>document.getElementById("loadSchedulesButton")).disabled = false;
                      }
                  },
        (error) => {
                  if(initLoad == false) {
          document.getElementById("loadSchedulesButton").classList.remove("is-loading");
    (<HTMLInputElement>document.getElementById("loadSchedulesButton")).disabled = false;
                      }
this.loadComplete = true;
this.productSchedules = productSchedulesTemp;
          this.toastr.error(error.message);
        }
              );
      }
      if(initLoad == false) {
        document.getElementById("loadSchedulesButton").classList.remove("is-loading");
      (<HTMLInputElement>document.getElementById("loadSchedulesButton")).disabled = false;
          }
    } catch(error) {
      if(initLoad == false) {
      document.getElementById("loadSchedulesButton").classList.remove("is-loading");
    (<HTMLInputElement>document.getElementById("loadSchedulesButton")).disabled = false;
          }
      this.loadComplete = true;
this.productSchedules = productSchedulesTemp;
      this.toastr.error(error.message);
}

  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.startYear = String(this.fromDate.year);
      this.startMonth = String(this.fromDate.month);
      this.startDay = String(this.fromDate.day);
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
      this.endYear = String(this.toDate.year);
      this.endMonth = String(this.toDate.month);
      this.endDay = String(this.toDate.day);
    } else {
      this.toDate = null;
      this.fromDate = date;
      this.startYear = String(this.fromDate.year);
      this.startMonth = String(this.fromDate.month);
      this.startDay = String(this.fromDate.day);
    }
  }

  async addSoftLock() {
    if(this.validUser === false) {
      await this.loginService.loginAnonymousUser();
      this.email = this.sessionService.getLocalKeyValues("user", "email");
      this.token = this.sessionService.getKeyValues("token", "id_token");
      // this.sessionService.set("redirect_url", this.r.url);
      // this.r.navigate(["/login"]);
      // return;
    }

    // if(ApplicationConstants.CONST_ADDRESS_CHECK_FOR_OFFER_BOOKING == "true" && this.userAddressCheck === false) {
    //   alert("Please update your profile before continuing. You need to give your address so that we can provide you with appropriate invoice.");
    //   return;
    // }

    if(this.selectedSlots.size <= 0) {
      alert("Please select valid slots for adding to cart.");
      return;
    }

    this.selectedSlots.forEach((slot, slotKey) => {
      var dayTemplateId = 0;
      if(slot.scheduleDays.includes("to")) {
        var startDate = (slot.scheduleDays.split(" to "))[0];
        var endDate = (slot.scheduleDays.split(" to "))[1];
      } else {
        var startDate = slot.scheduleDays;
        var endDate = slot.scheduleDays;
      }

      var slotArr = [];
      slot.selectedSchedules.forEach(function(schedule, key) {
        dayTemplateId = schedule.day_template_id;
        slotArr.push({
          actual_date: schedule.actual_date,
          slot_start_hour: schedule.slot_start_hour,
          slot_start_minutes: schedule.slot_start_minutes,
          slot_end_hour: schedule.slot_end_hour,
          slot_end_minutes: schedule.slot_end_minutes,
          slot_duration_hours: schedule.slot_duration_hours,
          slot_duration_minutes: schedule.slot_duration_minutes,
          slot_id: schedule.slot_id,
          day_template_id: dayTemplateId});
      });

      this.apollo
      .mutate({
        mutation: this.createSoftLockMutation.document,
        variables: {
          offeree_email: this.email,
          id_token: this.token,
          schedule_id: slot.schedule_id,
          product_id: this.productselectedId,
          schedule_frequency: slot.frequency,
          price: slot.price,
          start_date: startDate,
          end_date: endDate,
          slots: slotArr
        },
      })
      .subscribe(
        ({ data }) => {
          this.toastr.success("Offer slot added to the cart.");
          this.checkedBoxMap.set(slotKey, false);
          this.totalPrice = this.currencyPipe.transform(0);
          this.totalPriceInt = 0.0;
          this.selectedSlots = new Map();
          this.headerStickyComponent.callChildLoadSoftLocks();
          this.headerComponent.callChildLoadSoftLocks();
          this.headerMobileComponent.callChildLoadSoftLocks();
        },
        (error) => {
          this.toastr.error(error.message);
          this.checkedBoxMap.set(slotKey, false);
          this.totalPrice = this.currencyPipe.transform(0);
          this.totalPriceInt = 0.0;
          this.selectedSlots = new Map();
        }
      );
    });
  }

  timeChange(content, schedule) {
    if(this.selectedSlots != null && this.selectedSlots.has(schedule["cntr"])) {
      var selectedSlotData = this.getSelectedSchedule((content.target as HTMLInputElement).value, schedule);
      this.selectedSlots.set(schedule["cntr"], selectedSlotData);
    }
  }

  timeChangeAll($evt, schedule) {
    if(this.selectedSlots != null && this.selectedSlots.has(schedule["cntr"])) {
      this.selectedSlots.get(schedule["cntr"]).selectedSchedules.forEach(slot => {
      });
    }
  }

  timeGranularChangeAll($evt, schedule) {
    var selectedSchedule = ($evt.target as HTMLInputElement).value;
    schedule['slots'].forEach(slot => {
      var ele = document.getElementById('scheduleTimeSlotSelection' + schedule['cntr'] + '_' + slot['date']);
      var eleSelect = (ele as HTMLSelectElement);
      for(var i = 0; i < eleSelect.options.length; i++) {
        if(eleSelect.options[i].value == selectedSchedule) {
          eleSelect.selectedIndex = i;
          this.timeGranularChangeEle(ele, schedule, slot);
          var eleCheck = document.getElementById('checkbox_' + schedule['cntr'] + '_' + slot['date']);
          (eleCheck as HTMLInputElement).checked = true;
          this.selectGranularCheckBoxEle(eleCheck, schedule, slot);
          break;
        }
      }
    });

  }

  timeGranularChange($evt, schedule, slot) {
    this.timeGranularChangeEle($evt.target, schedule, slot);
  }

  timeGranularChangeEle(ele, schedule, slot) {
    var selectedSchedule = null;
    var scheduleMap = new Map;
    var selectedSlotData = null;

    if(schedule["frequency"] == "weekly" || schedule["frequency"] == "monthly" || schedule["frequency"] == "custom") {
      selectedSchedule = (ele as HTMLInputElement).value;

      slot["time_slots"].forEach(function(slotTemp, key) {
        if(selectedSchedule == key) {
          scheduleMap.set(slot.date, {
          actual_date: slot.date,
          slot_key: key,
          slot_start_hour: slotTemp.start_hour,
          slot_start_minutes: slotTemp.start_minute,
          slot_end_hour: slotTemp.end_hour,
          slot_end_minutes: slotTemp.end_minute,
          slot_duration_hours: slotTemp.slot_hours,
          slot_duration_minutes: slotTemp.slot_minutes,
          slot_id: slot.slot_id,
          day_template_id: slotTemp.day_template_id});
        }
      });
    }

    if(this.selectedSlots != null && this.selectedSlots.has(schedule["cntr"])) {
      selectedSlotData = this.selectedSlots.get(schedule["cntr"]);
      var tempScheduleMap = selectedSlotData.selectedSchedules;

      if((<HTMLInputElement> document.getElementById("checkbox_" + schedule["cntr"] + "_" + slot.date)).checked === true) {
        tempScheduleMap.set(slot.date, scheduleMap.get(slot.date));
      } else {
        tempScheduleMap.delete(slot.date);
      }

      if(tempScheduleMap == null || tempScheduleMap.size <= 0) {
        this.selectedSlots.delete(schedule["cntr"])
        selectedSlotData = null;
      } else {
        selectedSlotData.selectedSchedules = tempScheduleMap;
      }
    }

    if(selectedSlotData != null) {
      this.selectedSlots.set(schedule["cntr"], selectedSlotData);
    }
  }

  openTableModal(content, schedule) {
    this.checkedBoxGranularMap = new Map();
    this.selectBoxGranularMap = new Map();

    if(this.selectedSlots != null && this.selectedSlots.has(schedule["cntr"])) {
      this.selectedSlots.get(schedule["cntr"]).selectedSchedules.forEach(slot => {
        this.checkedBoxGranularMap.set(schedule["cntr"] + "_" + slot.actual_date, true);
        this.selectBoxGranularMap.set("scheduleTimeSlotSelection" + schedule["cntr"] + "_" + slot.actual_date, slot.slot_key);
      });
    }

    var modal = this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});

    modal.result.then((result) => {
      this.closeGuidelinesResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeGuidelinesResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }
  selectGranularCheckBoxEle(ele, schedule, slot) {
    var selectedSchedule = null;
    var scheduleMap = new Map;
    var selectedSlotData = null;

    if(schedule["frequency"] == "weekly" || schedule["frequency"] == "monthly" || schedule["frequency"] == "custom") {
      if((document.getElementById("scheduleTimeSlotSelection" + schedule["cntr"] + "_" + slot.date) as HTMLSelectElement).selectedIndex == -1) {
        selectedSchedule = (document.getElementById("scheduleTimeSlotSelection" + schedule["cntr"] + "_" + slot.date) as HTMLSelectElement).options[0].value;
        (document.getElementById("scheduleTimeSlotSelection" + schedule["cntr"] + "_" + slot.date) as HTMLSelectElement).selectedIndex  = 0;
      } else {
      selectedSchedule = (document.getElementById("scheduleTimeSlotSelection" + schedule["cntr"] + "_" + slot.date) as HTMLSelectElement).options[
        (document.getElementById("scheduleTimeSlotSelection" + schedule["cntr"] + "_" + slot.date) as HTMLSelectElement).selectedIndex
      ].value;
      }

      slot["time_slots"].forEach(function(slotTemp, key) {
        if(selectedSchedule == key) {
          scheduleMap.set(slot.date, {
          actual_date: slot.date,
          slot_key: key,
          slot_start_hour: slotTemp.start_hour,
          slot_start_minutes: slotTemp.start_minute,
          slot_end_hour: slotTemp.end_hour,
          slot_end_minutes: slotTemp.end_minute,
          slot_duration_hours: slotTemp.slot_hours,
          slot_duration_minutes: slotTemp.slot_minutes,
          slot_id: slot.slot_id,
          day_template_id: slotTemp.day_template_id});
        }
      });
    }

    if(this.selectedSlots != null && this.selectedSlots.has(schedule["cntr"])) {
      selectedSlotData = this.selectedSlots.get(schedule["cntr"]);
      var tempScheduleMap = selectedSlotData.selectedSchedules;
      if((ele as HTMLInputElement).checked === true) {
        tempScheduleMap.set(slot.date, scheduleMap.get(slot.date));
      } else {
        tempScheduleMap.delete(slot.date);
      }

      if(tempScheduleMap == null || tempScheduleMap.size <= 0) {
        this.selectedSlots.delete(schedule["cntr"])
        selectedSlotData = null;
      } else {
        selectedSlotData.selectedSchedules = tempScheduleMap;
      }
    } else if((ele as HTMLInputElement).checked === true) {
      selectedSlotData = {
        schedule_id: schedule["schedule_id"],
        schedules: schedule["schedules"], 
        price: schedule["intPrice"], 
        discounted_price: schedule["int_discounted_price"], 
        scheduleDays: schedule["schedule_days"], 
        slot_id: schedule["slot_id"],
        selectedSchedules: scheduleMap,
        frequency: schedule["frequency"],
        cntr: schedule["cntr"]
      };
    }

    if(selectedSlotData != null) {
      this.selectedSlots.set(schedule["cntr"], selectedSlotData);
    }

    var selectedSlotData = this.selectedSlots.get(schedule["cntr"]);
    if(selectedSlotData != null) {
      var tempScheduleMap = selectedSlotData.selectedSchedules;
      if(schedule["frequency"] == "weekly") {
        if(tempScheduleMap.size > 3 && 
          (<HTMLInputElement> document.getElementById("checkbox_" + schedule["cntr"])).checked === false) {
          (<HTMLInputElement> document.getElementById("checkbox_" + schedule["cntr"])).checked = true;
          (<HTMLInputElement> document.getElementById("checkboxgran_" + schedule["cntr"])).checked = true;
          this.checkedBoxMap.set(schedule["cntr"], true);
          if(schedule["int_discounted_price"] != null && schedule["int_discounted_price"] != 0) {
            this.totalPriceInt = this.totalPriceInt + schedule["int_discounted_price"];
          } else {
          this.totalPriceInt = this.totalPriceInt + schedule["intPrice"];
          }
        } else if(tempScheduleMap.size <= 3 && 
          (<HTMLInputElement> document.getElementById("checkbox_" + schedule["cntr"])).checked === true) {
          (<HTMLInputElement> document.getElementById("checkbox_" + schedule["cntr"])).checked = false;
          (<HTMLInputElement> document.getElementById("checkboxgran_" + schedule["cntr"])).checked = false;
          this.checkedBoxMap.set(schedule["cntr"], false);
          if(schedule["int_discounted_price"] != null && schedule["int_discounted_price"] != 0) {
            this.totalPriceInt = this.totalPriceInt - schedule["int_discounted_price"];
          } else {
          this.totalPriceInt = this.totalPriceInt - schedule["intPrice"];
        }
      }
        this.totalPrice = this.currencyPipe.transform(this.totalPriceInt);
      } else if (schedule["frequency"] == "monthly") {
        if(tempScheduleMap.size > 18 && 
          (<HTMLInputElement> document.getElementById("checkbox_" + schedule["cntr"])).checked === false) {
          (<HTMLInputElement> document.getElementById("checkbox_" + schedule["cntr"])).checked = true;
          (<HTMLInputElement> document.getElementById("checkboxgran_" + schedule["cntr"])).checked = true;
          this.checkedBoxMap.set(schedule["cntr"], true);
          if(schedule["int_discounted_price"] != null && schedule["int_discounted_price"] != 0) {
            this.totalPriceInt = this.totalPriceInt + schedule["int_discounted_price"];
          } else {
          this.totalPriceInt = this.totalPriceInt + schedule["intPrice"];
          }
        } else if(tempScheduleMap.size <= 18 && 
          (<HTMLInputElement> document.getElementById("checkbox_" + schedule["cntr"])).checked === true) {
          (<HTMLInputElement> document.getElementById("checkbox_" + schedule["cntr"])).checked = false;
          (<HTMLInputElement> document.getElementById("checkboxgran_" + schedule["cntr"])).checked = false;
          this.checkedBoxMap.set(schedule["cntr"], false);
        if(schedule["int_discounted_price"] != null && schedule["int_discounted_price"] != 0) {
            this.totalPriceInt = this.totalPriceInt - schedule["int_discounted_price"];
          } else {
          this.totalPriceInt = this.totalPriceInt - schedule["intPrice"];
          }
        }
        this.totalPrice = this.currencyPipe.transform(this.totalPriceInt);
      } else if (schedule["frequency"] == "custom") {
        if(tempScheduleMap.size > 0 && 
          (<HTMLInputElement> document.getElementById("checkbox_" + schedule["cntr"])).checked === false) {
          (<HTMLInputElement> document.getElementById("checkbox_" + schedule["cntr"])).checked = true;
          (<HTMLInputElement> document.getElementById("checkboxgran_" + schedule["cntr"])).checked = true;
          this.checkedBoxMap.set(schedule["cntr"], true);
          if(schedule["int_discounted_price"] != null && schedule["int_discounted_price"] != 0) {
            this.totalPriceInt = this.totalPriceInt + schedule["int_discounted_price"];
          } else {
          this.totalPriceInt = this.totalPriceInt + schedule["intPrice"];
          }
        } else if(tempScheduleMap.size <= 0 && 
          (<HTMLInputElement> document.getElementById("checkbox_" + schedule["cntr"])).checked === true) {
          (<HTMLInputElement> document.getElementById("checkbox_" + schedule["cntr"])).checked = false;
          (<HTMLInputElement> document.getElementById("checkboxgran_" + schedule["cntr"])).checked = false;
          this.checkedBoxMap.set(schedule["cntr"], false);
        if(schedule["int_discounted_price"] != null && schedule["int_discounted_price"] != 0) {
            this.totalPriceInt = this.totalPriceInt - schedule["int_discounted_price"];
          } else {
          this.totalPriceInt = this.totalPriceInt - schedule["intPrice"];
        }
      }
        this.totalPrice = this.currencyPipe.transform(this.totalPriceInt);
      }
    } else {
      if (schedule["frequency"] == "custom" && tempScheduleMap.size <= 0 && 
      (<HTMLInputElement> document.getElementById("checkbox_" + schedule["cntr"])).checked === true) {
        (<HTMLInputElement> document.getElementById("checkbox_" + schedule["cntr"])).checked = false;
        (<HTMLInputElement> document.getElementById("checkboxgran_" + schedule["cntr"])).checked = false;
        this.checkedBoxMap.set(schedule["cntr"], false);
        if(schedule["int_discounted_price"] != null && schedule["int_discounted_price"] != 0) {
          this.totalPriceInt = this.totalPriceInt - schedule["int_discounted_price"];
        } else {
        this.totalPriceInt = this.totalPriceInt - schedule["intPrice"];
        }
        this.totalPrice = this.currencyPipe.transform(this.totalPriceInt);
      }
    }
  }

  selectGranularCheckBox($evt, schedule, slot) {
    this.selectGranularCheckBoxEle($evt.target, schedule, slot);
  }

  getSelectedSchedule(selectedSchedule, schedule) {
    var scheduleMap = new Map;

    if(schedule["frequency"] != "weekly" && schedule["frequency"] != "monthly" && schedule["frequency"] != "custom") {
      
      schedule["schedules"].forEach(function(scheduleTemp, key) {
        if(selectedSchedule == key) {

          scheduleMap.set(schedule.schedule_days, {
            actual_date: schedule.schedule_days,
            slot_start_hour: scheduleTemp.start_hour,
            slot_start_minutes: scheduleTemp.start_minute,
            slot_end_hour: scheduleTemp.end_hour,
            slot_end_minutes: scheduleTemp.end_minute,
            slot_duration_hours: scheduleTemp.slot_hours,
            slot_duration_minutes: scheduleTemp.slot_minutes,
            slot_id: schedule["slot_id"],
            day_template_id: scheduleTemp.day_template_id});
        }
      });
    } else {
      schedule["slots"].forEach(slot => {
        let timeObj = slot.time_slots.values().next().value;
        let timeKey = slot.time_slots.keys().next().value;
        scheduleMap.set(slot.date, {
          actual_date: slot.date,
          slot_key: timeKey,
          slot_start_hour: timeObj.start_hour,
          slot_start_minutes: timeObj.start_minute,
          slot_end_hour: timeObj.end_hour,
          slot_end_minutes: timeObj.end_minute,
          slot_duration_hours: timeObj.slot_hours,
          slot_duration_minutes: timeObj.slot_minutes,
          slot_id: slot.slot_id,
          day_template_id: timeObj.day_template_id
        });
      });
    }

    return {
      schedule_id: schedule["schedule_id"],
      schedules: schedule["schedules"], 
      price: schedule["intPrice"], 
      discounted_price: schedule["int_discounted_price"],
      scheduleDays: schedule["schedule_days"], 
      slot_id: schedule["slot_id"],
      selectedSchedules: scheduleMap,
      frequency: schedule["frequency"],
      cntr: schedule["cntr"]
    };
  }

  selectCheckBox($evt, schedule, fromModal = false) {
    var selectedSchedule = null;

    if(schedule["frequency"] != "weekly" && schedule["frequency"] != "monthly" && schedule["frequency"] != "custom") {
      selectedSchedule = (document.getElementById("scheduleSlotSelection" + schedule["cntr"]) as HTMLSelectElement).options[
        (document.getElementById("scheduleSlotSelection" + schedule["cntr"]) as HTMLSelectElement).selectedIndex
      ].value;
    }

    var selectedSlotData = this.getSelectedSchedule(selectedSchedule, schedule);

    if($evt.currentTarget.checked === true) {
      this.checkedBoxMap.set(schedule["cntr"], true);
      this.selectedSlots.set(schedule["cntr"], selectedSlotData);
      if(schedule["int_discounted_price"] != null && schedule["int_discounted_price"] != 0) {
        this.totalPriceInt = this.totalPriceInt + parseFloat(schedule["int_discounted_price"]);
      } else {
      this.totalPriceInt = this.totalPriceInt + schedule["intPrice"];
      }
    } else if($evt.currentTarget.checked === false && this.selectedSlots.has(schedule["cntr"])) {
      this.selectedSlots.delete(schedule["cntr"]);
      this.checkedBoxMap.set(schedule["cntr"], false);
      if(schedule["int_discounted_price"] != null && schedule["int_discounted_price"] != 0) {
        this.totalPriceInt = this.totalPriceInt - parseFloat(schedule["int_discounted_price"]);
      } else {
      this.totalPriceInt = this.totalPriceInt - schedule["intPrice"];
    }
  }

    this.totalPrice = this.currencyPipe.transform(this.totalPriceInt);

    if(fromModal == true) {
      this.toggleAllScheduleChecks(schedule);
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  private _album = [];
  open(index: number): void {

    // open lightbox
    console.log('image');
    this._lightbox.open(this._album, index);
  }
  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  onAddCart(product: any) {
    console.log("No code");
  }

  onAddWishlist(product: any){
    console.log("No code");
  }

  toggleAllScheduleChecks(schedule) {
    
    if(this.selectedSlots != null && this.selectedSlots.has(schedule["cntr"])) {
      this.selectedSlots.get(schedule["cntr"]).selectedSchedules.forEach(slot => {
        var ele = <HTMLInputElement> document.getElementById("checkbox_" + schedule["cntr"] + "_" + slot.actual_date);
        ele.checked = true;
        var eleSelect = <HTMLSelectElement> document.getElementById("scheduleTimeSlotSelection" + schedule["cntr"] + "_" + slot.actual_date);
        eleSelect.selectedIndex = slot.slot_key;
      });
    } else {
      schedule['slots'].forEach(slot => {
        var ele = <HTMLInputElement> document.getElementById("checkbox_" + schedule["cntr"] + "_" + slot.date);
        ele.checked = false;
        var eleSelect = <HTMLSelectElement> document.getElementById("scheduleTimeSlotSelection" + schedule["cntr"] + "_" + slot.date);
        eleSelect.selectedIndex = 0;
      });
    }
  }

  openModal(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeGuidelinesResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeGuidelinesResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {
    this.route.paramMap.subscribe(
      params => {
        this.productselectedId = +params.get('id');
        this.productName = params.get('productname');
          // window.location.href = this.r.url;
      }
    );

    // const subscription = timer(50000).subscribe(() => {
      // const subscription = timer(50).subscribe(() => {
      this.loadPage = true;
      let token = this.sessionService.getKeyValues("token", "id_token");
      let email = this.sessionService.getLocalKeyValues("user", "email");
      let user_address_id = this.sessionService.getLocalKeyValues("user", "address_id");

    if(token != null && token != "" && email != null && email != "") {
      this.validUser = true;
      if(ApplicationConstants.CONST_ADDRESS_CHECK_FOR_OFFER_BOOKING == "true") {
        if(user_address_id != null && user_address_id != 0) {
          this.userAddressCheck = true;
        } else {
          this.userAddressCheck = false;
        }
      } else {
        this.userAddressCheck = true;
      }
    }

    this.token = token;
    this.email = email;

    this.totalPrice = this.currencyPipe.transform(0);

    this.productDetail();

    this.subscription = this.getCategoryQuery._categoryDataLoadedObservble.subscribe(
      (data) => {
        if (data === EventConstants.CONST_CATEGORY_LOADED) {
          let getprodCategory = this.getCategoryQuery.categoryData;
          this.addressGroupdata = this.getCategoryQuery.addressCategoryGroup;
          if (this.addressGroupdata[0] === false) {
            this.getCategoriesAddress = false;
          } else {
            this.getCategoriesAddress = true;
          }
            this.imageCategoriesdata =this.getCategoryQuery.imgCategoryGroup;
            if(this.imageCategoriesdata !== null){
              this.getCategoriesImage = true;
            } 
            else {
              this.getCategoriesImage = false;
              this.getImage == false;
            }
         }
      });
      window.scrollTo(0, 0)
    // });
  }

  getProductRating() {
    this.apollo
      .query({
        query: this.getProductRatingQuery.document,
        variables: {
          productId: this.productselectedId
        },
        fetchPolicy: "network-only"
      })
      .subscribe(
        ({ data }) => {
          if(data["getProductRating"] != null) {
            this.productRating = data["getProductRating"];

            this.likes = String(this.productRating.likes);

            if(this.productRating.likes > 999) {
              this.likes = (Math.floor((this.productRating.likes / 1000) * 100) / 100) + "K";
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  getCategoriesData() {
    this.apollo
      .query({
        query: this.getCategoryQuery.document,
        variables: {
          category: "",
          category_id: this.categoryId
        },
        fetchPolicy: "network-only"
      })
      .subscribe(
        ({ data }) => {
          this.getCategoryQuery.populateCategoryAndGroup(data);
          if(this.productImages.length == 0) {
            if(data["getProductCategories"][0].default_image_link == null) {
              this.productImages.push({
                id: 0,
                product_id: this.productselectedId,
                image: EventConstants.CONST_DEFAULT_OFFER_IMAGE,
                sort_order: 0,
                src: EventConstants.CONST_DEFAULT_OFFER_IMAGE
              });
                            this.loadAlbum();
            } else {
              this.productImages.push({
                id: 0,
                product_id: this.productselectedId,
                image: data["getProductCategories"][0].default_image_link,
                sort_order: 0,
                src: data["getProductCategories"][0].default_image_link
              });
                            this.loadAlbum();
            }
            
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  attachRandomUser(couponCodeEmailObj) {

    var couponCodeEmail = couponCodeEmailObj.value;
    const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/g;
    if(couponCodeEmail == null || couponCodeEmail == "" || !couponCodeEmail.match(emailRegex)) {
      this.toastr.error("Please enter a valid email address.");
      return;
    }

    if(this.sessionService.checkKey(SessionConstants.CONST_MASKED_USER_KEYWORD) === true) {
      var maskedUserData = this.sessionService.getKeyValues(SessionConstants.CONST_MASKED_USER_KEYWORD, "masked_id");
      var user_id = this.sessionService.getKeyValues(SessionConstants.CONST_MASKED_USER_KEYWORD, "id");
    
      this.randomUserService.attachMaskedUserDetails(user_id, maskedUserData, couponCodeEmail, null);
      var offerName = "";
      if(this.couponCodeData["offer_id"] != null && this.couponCodeData["offer_id"] != 0) {
        offerName = this.getProductDetailsData["product_name"];
      }
      
      this.randomUserService.sendCouponCodeEmail(
        couponCodeEmail,
        this.couponCodeData["coupon_code"],
        this.couponCodeData["discount_percentage"],
        this.couponCodeData["start_date"],
        this.couponCodeData["end_date"],
        this.couponCodeData["offer_id"],
        this.couponCodeData["offer_title"],
        offerName,
        this.couponCodeData["category"],
        this.couponCodeData["sub_category1"],
        this.couponCodeData["sub_category2"],
        this.couponCodeData["category_group"],
        this.couponCodeData["for_all_offers"],
        this.couponCodeData["category_id"],
        this.couponCodeData["category_group_id"]
      );

      if(this.couponCodeData["existing"] == false || this.couponCodeData["existing"] == "false" || this.couponCodeData["existing"] == null) {
        this.apollo
        .mutate({
          mutation: this.registerNewCouponCodeMutation.document,
          variables: {
            coupon_code: this.couponCodeData["coupon_code"],
            discount_percentage: this.couponCodeData["discount_percentage"],
            start_date: this.couponCodeData["start_date"],
            end_date: this.couponCodeData["end_date"],
            offer_id: this.couponCodeData["offer_id"],
            category_id: this.couponCodeData["category_id"],
            category_group_id: this.couponCodeData["category_group_id"]
          },
          fetchPolicy: "network-only"
        })
        .subscribe(
          ({ data }) => {
          this.apollo
            .mutate({
              mutation: this.trackLeadGenCouponDealMutation.document,
              variables: {
                masked_id: maskedUserData,
                discount_percentage: this.couponCodeData["discount_percentage"],
                start_date: this.couponCodeData["start_date"],
                end_date: this.couponCodeData["end_date"],
                surprise_flag: this.couponCodeSurpriseFlag,
                offer_id: this.couponCodeData["offer_id"],
                category_id: this.couponCodeData["category_id"],
                category_group_id: this.couponCodeData["category_group_id"],
              },
            })
            .subscribe(
              ({ data }) => {
                this.modalService.dismissAll();
                this.toastr.success("Thank you for your email. We will send you the coupon code shortly.");
              },
              (error) => {
                this.toastr.error(error.message);
              }
            );
          },
          (error) => {
            this.toastr.error(error.message);
          }
        );
      } else {
        this.apollo
            .mutate({
              mutation: this.trackLeadGenCouponDealMutation.document,
              variables: {
                masked_id: maskedUserData,
                discount_percentage: this.couponCodeData["discount_percentage"],
                start_date: this.couponCodeData["start_date"],
                end_date: this.couponCodeData["end_date"],
                surprise_flag: this.couponCodeSurpriseFlag,
                offer_id: this.couponCodeData["offer_id"],
                category_id: this.couponCodeData["category_id"],
                category_group_id: this.couponCodeData["category_group_id"],
              },
            })
            .subscribe(
              ({ data }) => {
                this.modalService.dismissAll();
                this.toastr.success("Thank you for your email. We will send you the coupon code shortly.");
              },
              (error) => {
                this.toastr.error(error.message);
              }
            );
      }
    }
    
  }

  productDetail() {
    let productSelectedId = this.productselectedId;

    let token = this.sessionService.getKeyValues("token", "id_token");
    let email = this.sessionService.getLocalKeyValues("user", "email");

    this.apollo
      .query({
        query: this.getProductDetailsQuery.document,
        variables: {
          product_id: productSelectedId,
          product_name: this.productName,
          id_token: token,
          lender_email: email
        },
        fetchPolicy: "network-only"
      })
      .subscribe(
        ({ data }) => {
          if (data["getProductDetails"]) {
            this.getProductDetailsData = data["getProductDetails"];
            this.productselectedId = this.getProductDetailsData.id;
            this.loadBriefOfferInfo();
            this.getProductImages();
            this.getProductAttribute();
            this.getProductRating();
            this.loadSchedules(true);

            if(this.sessionService.checkKey(SessionConstants.CONST_MASKED_USER_KEYWORD) === true) {
              var maskedUserData = this.sessionService.getKeyValues(SessionConstants.CONST_MASKED_USER_KEYWORD, "masked_id");
              var user_id = this.sessionService.getKeyValues(SessionConstants.CONST_MASKED_USER_KEYWORD, "id");
              var random_email = this.sessionService.getKeyValues(SessionConstants.CONST_MASKED_USER_KEYWORD, "email");
        
              this.apollo
                    .mutate({
                      mutation: this.trackOfferViewMutation.document,
                      variables: {
                        masked_id: maskedUserData,
                        user_id: user_id,
                        offer_id: this.getProductDetailsData.id,
                        category_id: this.getProductDetailsData.category_id
                      },
                  }).subscribe(({ data }) => {});


                  if((token == null || token == "" || email == null || email == "") && (random_email == null || random_email == "")) {
                    this.apollo
                    .mutate({
                      mutation: this.generateCouponCodeMutation.document,
                      variables: {
                        masked_user_id: maskedUserData,
                        offer_id: this.getProductDetailsData.id
                      },
                      fetchPolicy: "network-only"
                    })
                    .subscribe(
                      ({ data }) => {
                        this.dealModalPopupText = "";
                        this.dealModalPopupDealString = "";
                        if(data["generateCouponCode"] != null) {
                          this.couponCodeData = data["generateCouponCode"];
                          var dealTextArray = ["surprise", "amazing", "fantastic", "awesome", "superb", "wonderful", "incredible", "unbelievable", "marvelous", "stunning", "astonishing", "extraordinary", "remarkable", "mind-blowing", "jaw-dropping", "breathtaking", "awe-inspiring", "magnificent", "spectacular", "splendid", "glorious", "majestic", "grand", "impressive", "excellent", "outstanding", "exceptional", "brilliant", "superior", "first-class", "top-notch", "premium", "elite", "world-class", "top-class"];
                          var dealArray = ["surprise", "% discount", "surprise", "% discount"];
                          let dealIndex = Math.floor(Math.random() * 4);
                          let dealStringIndex = Math.floor(Math.random() * 35);

                          if(dealArray[dealIndex] == "surprise") {
                            this.couponCodeSurpriseFlag = true;
                            this.dealModalPopupDealString = dealTextArray[dealStringIndex];
                          } else {
                            this.dealModalPopupDealString = this.couponCodeData["discount_percentage"] + dealArray[dealIndex];
                          }

                          if(this.couponCodeData["offer_id"] != null && this.couponCodeData["offer_id"] != 0) {
                            this.dealModalPopupText = "Applicable to this offer: " + this.couponCodeData["offer_title"];
                          } else if(this.couponCodeData["category_id"] != null && this.couponCodeData["category_id"] != 0) {
                            if(this.couponCodeData["category_group"] != null && this.couponCodeData["category_group"] != "") {
                              this.dealModalPopupText = "Applicable to all Offers in the category: " + this.couponCodeData["category_group"] + " | " + this.couponCodeData["category"];
                            } else {
                              this.dealModalPopupText = "Applicable to all Offers in the category: " + this.couponCodeData["category"];
                            }
                            
                            if(this.couponCodeData["sub_category1"] != null && this.couponCodeData["sub_category1"] != "") {
                              this.dealModalPopupText += " | " + this.couponCodeData["sub_category1"];
                            }
                            if(this.couponCodeData["sub_category2"] != null && this.couponCodeData["sub_category2"] != "") {
                              this.dealModalPopupText += " | " + this.couponCodeData["sub_category2"];
                            }
                          } else if(this.couponCodeData["for_all_offers"] == true) {
                            this.dealModalPopupText = "Applicable to all Offers";
                          }

                          if(this.sessionService.checkKey("coupon_code_window") === false) {
                            if(this.dealModalPopupText != "") {
                              setTimeout(() => {
                                this.openModal(this.dealModalPopup);
                                this.sessionService.setCookie("coupon_code_window", "true", true, 1440);
                              }, 5000);
                            }
                          }
                        }
                      },
                      (error) => {
                        console.log(error);
                      }
                    );
                  }      
            }

            if(this.getProductDetailsData != null) {
                            this.categoryId = this.getProductDetailsData.category_id;
                            if (this.getProductDetailsData.status === 1) {
                        this.productDescription = this.getProductDetailsData.product_description;
                this.guidelines = this.getProductDetailsData.guidelines;
                              this.getCategoriesData();
                return this.statusdata = "yes";
              } else if (this.getProductDetailsData.status === 0) {
                                this.productDescription = this.getProductDetailsData.product_description;
                this.guidelines = this.getProductDetailsData.guidelines;
                this.getCategoriesData();
                return this.statusdata = "no";
              }
            }
          }
        },
        (error) => {
          this.loadComplete = true;
          console.log("error");
        }
      );
  }

  nl2br(str){
    return str.replace(/(?:\r\n|\r|\n)/g, '<br>');
   }

  getProductImages() {
    let productSelectedId = this.productselectedId;
    this.apollo
      .query({
        query: this.getProductImagesQuery.document,
        variables: {
          product_id: productSelectedId
        },
        fetchPolicy: "network-only"
      })
      .subscribe(
        ({ data }) => {
         if(data != null && data["getProductImages"].length > 0) {
          this.productImages = [];
           var cnt = 1;
           data["getProductImages"].forEach(image => {
             this.productImages.push({
               id: image.id,
               products_id: productSelectedId,
               image: image.link,
               sort_order: cnt,
               src: image.link
             });
             cnt++;
           });
                     this.loadAlbum();
         } else {
          this.productImages = [];
         }

          this.prodImageLinkData = this.getProductImagesQuery.prodImageLink;
        if (this.prodImageLinkData !== null) {
            this.getCategoriesImage = false;
              this.getImage == true;
            }
            else {
              this.getImage == false;
              this.getCategoriesImage = false;
            }
        },
        (error) => {
          this.loadComplete = true;
          console.log("error");
        }
      );
  }
  getProductAttribute() {
    let productSelectedId = this.productselectedId;
    this.apollo
      .query({
        query: this.getProductAttributesQuery.document,
        variables: {
          product_id: productSelectedId,
          attribute_key: ""
        },
      })
      .subscribe(
        ({ data }) => {
          if (data["getProductAttributes"]) {
            this.getProductAttributeData = data["getProductAttributes"];
          }
          (error) => {
this.loadComplete = true;
            console.log("error");
          }
        }
      );
  }
  OnBack() {
    this.r.navigate(['search']);
  }

  slideProductConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "infinite": false,
    //"asNavFor": "slideProductNavConfig",
    "asNavFor": ".thumbs",
  };

  slideProductNavConfig = {
    "slidesToShow": 3,
    "slidesToScroll": 1,
    "asNavFor": ".feedback",
    //"asNavFor": "slideProductConfig",
    "centerMode": true,
    "centerPadding": '60px',
    "dots": true,
    "arrows": false,
    "focusOnSelect": true
  };

  slideRelatedProductConfig = {
    "slidesToShow": 4,
    "slidesToScroll": 1,
    "dots": true,
    "infinite": false,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]

  };

  slickInit(e) {
    console.log('slick initialized');
  }
}