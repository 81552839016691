import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { interval } from "rxjs";
import { ApplicationConstants } from "./constants/app.constants";
import { LoginUserMutation } from "./services/graphql/login.graphql";
import { RegisterMaskedUserMutation } from "./services/graphql/registerMaskedUser.graphql";
import { AttachMaskedUserDetailsMutation } from "./services/graphql/attachMaskedUserDetails.graphql";
import { GetMaskedUserDetailsQuery } from "./services/graphql/getMaskedUserDetails.graphql";
import { SessionService } from "./services/session.service";
import { RandomUserService } from "./services/randomeUser.service";
import { Apollo, gql } from "apollo-angular";
import { SessionConstants } from "src/app/constants/app.constants";
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, Event, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import AOS from 'aos';


declare const gtag: Function; // <------------Important: the declartion for gtag is required!

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",

  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "zigwik";
  errorMessage: string;
  isLoggedIn: boolean = true;
  refresh_token = "";
  email = "";
  loginTime = 0;

  public currencies = [
    {
      name: "India INR",
      code: "INR",
      symbolLeft: "₹",
      symbolRight: "",
      decimalPlaces: "2",
      Value: "1",
    }
  ];

  constructor(private translate: TranslateService, 
    private sessionService: SessionService,
    private apollo: Apollo, 
    private loginUserMutation: LoginUserMutation,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private registerMaskedUserMutation: RegisterMaskedUserMutation,
    private attachMaskedUserDetailsMutation: AttachMaskedUserDetailsMutation,
    private getMaskedUserDetailsQuery: GetMaskedUserDetailsQuery,
    private randomUserService: RandomUserService
  ) {
    // set default language and translation
    translate.addLangs(["en"]);
    const setDefaultlanaguage = localStorage.getItem("currency");

    if (
      localStorage.getItem("lang") === null ||
      localStorage.getItem("lang") === undefined
    ) {
      localStorage.setItem("lang", "en");
      this.translate.use("en");
      translate.setDefaultLang("en");
    } else {
      translate.setDefaultLang(localStorage.getItem("lang"));
    }

    // set default currency
    if (setDefaultlanaguage === null || setDefaultlanaguage === undefined) {
      localStorage.setItem("currency", JSON.stringify(this.currencies[0]));
    }

    /** START : Code to Track Page View using gtag.js */
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
       gtag('event', 'page_view', {
          page_path: event.urlAfterRedirects
       })
      })
      /** END : Code to Track Page View  using gtag.js */

      //Add dynamic title for selected pages - Start
      router.events.subscribe(event => {
        if(event instanceof NavigationEnd) {
          var title = this.getTitle(router.routerState, router.routerState.root).join(' > ');
          titleService.setTitle(title);
        }
      });
  }

  async ngOnInit() {
    var currTime = new Date();
    AOS.init({disable: 'mobile'});//AOS - 2
    AOS.refresh();//refresh method is called on window resize and so on, as it doesn't require to build new store with AOS elements and should be as light as possible.
    if(this.sessionService.checkKey(SessionConstants.CONST_MASKED_USER_KEYWORD) === true) {

      var maskedUserData = this.sessionService.getKeyValues(SessionConstants.CONST_MASKED_USER_KEYWORD, "masked_id");
      var email = this.sessionService.getKeyValues(SessionConstants.CONST_MASKED_USER_KEYWORD, "email");
      var phone = this.sessionService.getKeyValues(SessionConstants.CONST_MASKED_USER_KEYWORD, "phone");
      var user_id = this.sessionService.getKeyValues(SessionConstants.CONST_MASKED_USER_KEYWORD, "id");

      var maskedUserDetails = await this.getMaskedUserDetailsQuery.fetch({
        user_id: user_id,
        masked_id: maskedUserData,
        email: email,
        phone: phone
      }).toPromise();

      if(maskedUserDetails != null && maskedUserDetails["data"] != null && maskedUserDetails["data"]["getMaskedUserDetails"] != null) {
        var data = maskedUserDetails["data"];
        if(data["getMaskedUserDetails"] != null && data["getMaskedUserDetails"]["id"] != null) {
          this.sessionService.set(SessionConstants.CONST_MASKED_USER_KEYWORD, data["getMaskedUserDetails"]);
        } else {
          this.sessionService.delete(SessionConstants.CONST_MASKED_USER_KEYWORD);
          var maskedUserNew = await this.registerMaskedUserMutation.mutate().toPromise();

          if(maskedUserNew != null && maskedUserNew["data"] != null && maskedUserNew["data"]["registerMaskedUser"] != null) {
            var data = maskedUserNew["data"];
            if(data["registerMaskedUser"] != null && data["registerMaskedUser"]["id"] != null) {
              this.sessionService.set(SessionConstants.CONST_MASKED_USER_KEYWORD, data["registerMaskedUser"]);
            }
          }
        }
      }
    } else {
      var maskedUserNew = await this.registerMaskedUserMutation.mutate().toPromise();

      if(maskedUserNew != null && maskedUserNew["data"] != null && maskedUserNew["data"]["registerMaskedUser"] != null) {
        var data = maskedUserNew["data"];
        if(data["registerMaskedUser"] != null && data["registerMaskedUser"]["id"] != null) {
          this.sessionService.set(SessionConstants.CONST_MASKED_USER_KEYWORD, data["registerMaskedUser"]);
        }
      }
    }






    if(this.sessionService.checkKey(SessionConstants.CONST_SESSION_TOKEN_KEYWORD) === true) {
      this.loginTime = this.sessionService.getKeyValues("token", "login_time");

      var refreshTokenCycleTimeCap = this.loginTime + ApplicationConstants.CONST_REFRESH_TOKEN_INTERVAL;

      if(currTime.getTime() < refreshTokenCycleTimeCap) {
        this.refresh_token = this.sessionService.getKeyValues("token", "refresh_token");
        this.isLoggedIn = true;
        this.email = this.sessionService.getKeyValues("token", "email");

        let refreshToken = this.sessionService.getKeyValues("token", "refresh_token");
        let email = this.sessionService.getLocalKeyValues("user", "email");

        await this.refreshTokenCall(email, refreshToken);
      } else {
        this.sessionService.delete("token");
        this.sessionService.delete("user_verified_user_check");
        this.sessionService.deleteLocal("user");
      }
    }
    this.initiateTimer();
  }

// collect that title data properties from all child routes
  // there might be a better way but this worked for me
  getTitle(state, parent) {
    var data = [];
    if(parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if(state && parent) {
      data.push(... this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }


  initiateTimer() {
    interval(ApplicationConstants.CONST_ID_TOKEN_INTERVAL).subscribe
      ((data) => {
        if(this.sessionService.checkKey("token") == true) {
        let idtokenData = JSON.parse(this.sessionService.get("token"));
        if (idtokenData !== null && idtokenData.id_token !== null && 
          idtokenData.email !== null) {
            this.refresh_token = idtokenData.refresh_token;
          this.isLoggedIn = true;
          this.email = idtokenData.email;
            this.refreshTokenCall(this.email, this.refresh_token);
          } else {
            this.isLoggedIn = false;
          }
        } else {
          this.isLoggedIn = false;
        }
      },
      );
  }
  
  async refreshTokenCall(emailId: String, refreshToken: String) {
      try {
      var loginData = await this.apollo
      .mutate({
        mutation: this.loginUserMutation.document,
        variables: {
          email: emailId,
          refresh_token: refreshToken
        },
      }).toPromise();

      if(loginData != null && loginData["data"] != null && loginData["data"]["loginUser"] != null) {
        var data = loginData["data"];

          if(this.sessionService.checkKey(SessionConstants.CONST_MASKED_USER_KEYWORD) === true) {
            var maskedUserData = this.sessionService.getKeyValues(SessionConstants.CONST_MASKED_USER_KEYWORD, "masked_id");
            var user_id = this.sessionService.getKeyValues(SessionConstants.CONST_MASKED_USER_KEYWORD, "id");
            this.randomUserService.attachMaskedUserDetails(user_id, maskedUserData, emailId, null);
          }

          data["loginUser"].valid_user = true;
          data["loginUser"].email = emailId;
          this.loginTime = (new Date()).getTime();
          data["loginUser"].login_time = this.loginTime;
          data["loginUser"]["login_time"] = this.loginTime;
          this.sessionService.set(SessionConstants.CONST_SESSION_TOKEN_KEYWORD, data["loginUser"]);
          if(this.sessionService.checkKey("refresh_cookie_flag") == false) {
            this.sessionService.setCookie("refresh_cookie_flag", "true", true, 55);
            window.location.reload();
          }
      }
      } catch (error) {
          this.sessionService.deleteLocal("user");
          this.sessionService.deleteLocal("anonymousUser");
          this.sessionService.deleteLocal("validAnonymousUser");
          this.sessionService.delete("refresh_cookie_flag");
          this.sessionService.delete("token");
          this.sessionService.delete("user_verified_user_check");
          this.sessionService.delete("referral_code_signup_value");
          window.location.reload();
      }
     }
}
